<template>
  <div :class="colSize" class="row self-start q-mt-md q-py-sm">
    <div class="col-12 q-px-sm q-pt-md text-h5">Bedrijfgegevens</div>
    <div class="col-6 q-px-sm q-my-sm">
      <q-input
        v-if="settings?.companyName?.enabled ?? true"
        dense
        outlined
        :modelValue="companyName"
        @change="companyName = $event"
        label="Bedrijfsnaam"
        :rules="validate('required50')"
      />
    </div>
    <div class="col-6 q-px-sm q-my-sm">
      <q-input
        v-if="settings?.KVK_nummer?.enabled ?? true"
        :readonly="readonly"
        dense
        outlined
        :modelValue="KVK_nummer"
        label="KVK"
        mask="########"
        @change="KVK_nummer = $event"
        :rules="validate('kvk')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useQuasar } from "quasar";
import { useStore } from "vuex";
import { validate, validator } from "../../plugins/validation.js";

//* Props
// eslint-disable-next-line no-undef, no-unused-vars
const props = defineProps({
  settings: Object,
});

//* VueUse
const $q = useQuasar();
const $store = useStore();

//* Refs

//* Computed
const colSize = computed(() => ($q.screen.lt.md ? "col-12" : "col-6"));
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);

const companyName = computed({
  get() {
    return $store.getters.record?.Bedrijfsnaam ?? "";
  },
  set(newVal) {
    $store.dispatch("updateRecord", {
      key: "Bedrijfsnaam",
      value: newVal,
      validated: validator(validate("required50"), newVal),
    });
  },
});

const KVK_nummer = computed({
  get() {
    return $store.getters.record?.KVK_nummer ?? "";
  },
  set(newVal) {
    $store.dispatch("updateRecord", {
      key: "KVK_nummer",
      value: newVal,
      validated: validator(validate("kvk"), newVal),
    });
  },
});

//* Methods
</script>
